import { useCallback } from 'react';

const FALLBACK_LANG = 'en';

const translations = {
  data_protection_portal_of: {
    en: '{dealer_name}\'s Data protection portal',
    es: 'Portal de protección de datos de {dealer_name}',
    it: 'Portale di protezione dei dati {dealer_name}',
    fr: 'Portail de protection des données de {dealer_name}',
  },
  data_updated_successfully: {
    en: 'Your data has been successfully updated',
    es: 'Tus datos se han actualizado con éxito',
    it: 'I tuoi dati sono stati aggiornati con esito',
    fr: 'Vos données ont été actualisées correctement',
  },
  your_information: {
    en: 'Your information',
    es: 'Tus datos',
    it: 'I tuoi dati',
    fr: 'Vos données',
  },
  consents: {
    en: 'Consents',
    es: 'Consentimientos',
    it: 'Consensi',
    fr: 'Consentements',
  },
  name: {
    en: 'Name',
    es: 'Nombre',
    it: 'Nome',
    fr: 'Nom',
  },
  email: {
    en: 'Email',
    es: 'E-mail',
    it: 'E-mail',
    fr: 'Email',
  },
  phone: {
    en: 'Phone',
    es: 'Teléfono',
    it: 'Telefono',
    fr: 'Numéro de téléphone',
  },
  gdpr_privacy_policy: {
    en: `I have read and accept the <a href="{privacy_policy_url}" target="_blank">privacy policy</a> from {legal_name}`,
    es: `Sí, he leído y acepto la <a href="{privacy_policy_url}" target="_blank">política de privacidad</a> de {legal_name}`,
    it: 'Si, ho letto e accetto la <a href="{privacy_policy_url}" target="_blank">politica di privacy</a> de {legal_name}',
    fr: 'Oui, j\'ai lu et accepté la <a href="{privacy_policy_url}" target="_blank">politique de confidentialité</a> de {legal_name}',
  },
  gdpr_marketing: {
    en: 'I wish to receive marketing communications from {legal_name}',
    es: 'Sí, deseo recibir comunicaciones comerciales de {legal_name}',
    it: 'Si, vorrei ricevere comunicazioni commerciali da {legal_name}',
    fr: 'Oui, je souhaite recevoir des communications commerciales de {legal_name}',
  },
  gdpr_profiling: {
    en: 'I consent to {legal_name} adapting information relative to the use of our products and services to provide a personalized experience.',
    es: 'Sí, acepto que {legal_name} adapte la información relativa a cómo usa nuestros productos y servicios para poder recibir una atención personalizada.',
    it: 'Si, accetto che {legal_name} adatti l\'informazione relativa a come tratta i nostri prodotti e servizi per poter ricevere un\'attenzione personalizzata.',
    fr: 'Oui, j\'accepte que {legal_name} adapte l\'information relative à l\'utilisation de nos produits et services pour recevoir une attention personnalisée.',
  },
  send: {
    en: 'Send',
    es: 'Enviar',
    it: 'Inviare',
    fr: 'Envoyer',
  },
  already_validated: {
    en: 'You have already validated your contact details',
    es: 'Ya has validado tus datos de contacto',
    it: 'Hai già validato i tuoi dati di contatto',
    fr: 'Vos données de contact ont déjà été validées',
  },
  data_loading: {
    en: 'Loading your data',
    es: 'Estamos cargando tus datos',
    it: 'Stiamo caricando i tuoi dati',
    fr: 'Vos données sont en train de charger',
  },
};

const useBasicI18N = () => {

  const lang = window.navigator.language?.split(/-/)[0].toLowerCase();

  const getTranslation = useCallback((translation_key, values = {}) => {
    const unreplaced_translation = (
      translations[translation_key]?.[lang] ||
      translations[translation_key]?.[FALLBACK_LANG]
    );
    const replaced_translation = Object.entries(values)
      .reduce((translation, [key, value]) =>
        translation?.replace(`{${key}}`, value),
      unreplaced_translation);
    return replaced_translation;
  }, [lang]);

  return getTranslation;
};

export default useBasicI18N;
